<template>
  <div v-if="!cookiesSeen" class="cookie-banner">
    <p class="cookie-banner__text" v-html="$t('cookies_banner_text')" />
    <div class="cookie_banner__actions">
      <app-button
        light
        small
        secondary
        @click="setCookiesDeclined"
      >
        {{ $t('decline_all') }}
      </app-button>
      <app-button
        light
        small
        @click="setCookiesAccepted"
      >
        {{ $t('accept_all') }}
      </app-button>
    </div>
  </div>
</template>

<script setup>
  import { useUserStore } from '~/stores/user'

  const { $clientCookieService, $analyticsService } = useNuxtApp()
  const userStore = useUserStore()
  const route = useRoute()
  const COOKIES_SEEN_KEY = 'cookie_banner_seen'
  const COOKIES_ACCEPTED = 'cookies_accepted'

  const cookiesSeen = ref(true)

  function setCookiesDeclined() {
    $clientCookieService.set(COOKIES_ACCEPTED, false, { expires: 180 })
    setCookiesSeen()
  }

  function setCookiesAccepted() {
    $clientCookieService.set(COOKIES_ACCEPTED, true, { expires: 180 })
    setCookiesSeen()
    updateConsent()
  }

  function updateConsent() {
    userStore.acceptedCookies()
    $analyticsService.updateConsentCookie({
      'ad_storage': 'granted',
      'ad_user_data': 'granted',
      'ad_personalization': 'granted',
      'analytics_storage': 'granted',
    })
  }

  function setCookiesSeen() {
    $clientCookieService.set(COOKIES_SEEN_KEY, true, { expires: 180 })
    cookiesSeen.value = true
  }

  function checkGclid() {
    const gclid = route.query['gclid']

    if (gclid) $analyticsService.saveGclid(gclid)
  }

  onMounted(() => {
    checkGclid()
    cookiesSeen.value = $clientCookieService.get(COOKIES_SEEN_KEY)
    const cookiesAccepted = $clientCookieService.get(COOKIES_ACCEPTED)

    if (cookiesAccepted) {
      updateConsent()
    }
  })
</script>

<style lang="scss">
  .cookie-banner {
    position: fixed;
    bottom: 0;
    padding:var(--spacing-default);
    left: 0;
    right: 0;
    background: var(--color-primary-85);
    color: var(--color-lightest);

    @include breakpoint(md) {
      display: flex;
    }
  }

  .cookie-banner__text {
    font-size: 12px;
    padding-right: var(--spacing-large);
    margin-bottom: var(--spacing-default);

    @include breakpoint(md) {
      font-size: 14px;
      margin-bottom: 0;
    }
  }

  .cookie_banner__actions {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: var(--spacing-default);

    @include breakpoint(sm) {
      display: flex;
      justify-content: flex-end;
      flex-shrink: 0;
    }
  }
</style>
